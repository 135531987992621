// 1. import `extendTheme` function
import { extendTheme, theme as baseTheme, baseStyle } from "@chakra-ui/react";
import { mode, createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
  "3xl": "2100px",
});

// 2. Add your color mode config
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
  breakpoints: breakpoints,
  styles: {
    global: (props) => ({
      body: {
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("white", "gray.900")(props),
        fontWeight: 400,
      },
      p: {
        fontWeight: 400,
      },
    }),
  },
  fonts: {
    heading: "Spline Sans",
    body: "Spline Sans",
  },
  colors: {
    gray: {
      400: "#595959",
      500: "#474747",
      600: "#363636",
      700: "#242424",
      800: "#121212",
      900: "#000000",
    },
    brand: {
      50: "#FAFAFF",
      100: "#E7E8FE",
      200: "#C0C4FC",
      300: "#99A0FA",
      400: "#727CF8",
      500: "#4C58F6",
      600: "#3D4BF5",
      700: "#2F3DF4",
      800: "#2030F3",
      900: "#1222F3",
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "brand.300",
        variant: "filled",
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: "brand.300",
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "brand.300",
        variant: "filled",
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Link: {
      // set a base style of underline on hover
      baseStyle: {
        _hover: {
          textDecoration: "underline",
        },
      },
    },
    Form: {
      baseStyle: (props) => ({
        helperText: {
          lineHeight: 1.6,
          fontWeight: "medium",
        },
      }),
    },
  },
};

// 3. extend the theme
const theme = extendTheme(config);

export default theme;
